interface ApiConfig {
  API_URL: string;
}

interface Config {
  api: ApiConfig;
}

const config: Config = {
  api: {
    API_URL: "https://api.aranathgroup.com/api",
  },
};
export const configImage: Config = {
  api: {
    API_URL: "https://api.aranathgroup.com/uploads",
  },
};

export default config;
